.scope-select-modal {
  width: 100% !important;
  border: 0 !important;
  box-shadow: none;
  margin-left: 0.25em;
  vertical-align: top !important;
  transition: opacity 500ms ease-out, margin-top 500ms ease-out;
  display: flex;
  align-items: center;

  .scope-select-title {
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    padding: 0.4em 0.6em 0.4em 0.6em;
    background-color: var(--primary-color-darker);
    font-size: 1em;
    color: white;
    .scope-select-title-text {
      padding-left: 0.5em;
    }
  }

  .loading-mask-spinner {
    display: none;
  }

  .scope-select {
    padding: 2.5em;
    min-height: 9em;
    text-align: center;
    .error-text {
      position: absolute;
      z-index: 99;
      left: calc(50% - 168px);
      top: -11px;
    }
  }

  &.scope-loading {
    margin-top: 2em;
    .floating-box:nth-child(even) {
      z-index: 1;
    }
    &.scope-select-modal-entered {
      margin-top: 2em;
    }
  }
  &.scope-select-modal-entering {
    opacity: 0;
    margin-top: 0;
  }
  &.scope-select-modal-entered {
    opacity: 1;
    margin-top: 3em;
  }
  &.scope-select-modal-exiting,
  &.scope-select-modal-exited {
    margin-top: 12em !important;
    opacity: 0;
  }
  .modal---close---28rLZ {
    display: none;
  }
}

.scope-overlay {
  transition: opacity 200ms ease-out, background-color 200ms ease-out;
  &.scope-loading {
    cursor: not-allowed;
    opacity: 1;
    background-color: var(--black-50);
    &.scope-overlay-entered {
      opacity: 1;
      background-color: var(--black-50);
    }
  }
  &.scope-overlay-entering,
  &.scope-overlay-exiting &.scope-overlay-exited {
    opacity: 0;
  }
  &.scope-overlay-header-space {
    top: 63px;
  }
}

.pdf-modal {
  .titled-modal-header {
    font-size: 1em;
    line-height: 1em;
  }
  .modal---close---28rLZ {
    display: none;
  }
  .pdf-modal-body {
    text-align: center;
    font-size: 0.8em;
    padding: 1.2em;
  }
}

.initialize-plan-modal.titled-modal {
  max-width: 40em;
  height: inherit;
  border: 0 !important;

  .modal---modal-body---2HFnG.modal---padding---2ENnv {
    padding-top: 0 !important;
  }
  .modal---modal-footer---20LPi {
    background-color: inherit !important;
  }
  .initialize-plan-modal-button {
    border-radius: 0;
    font-weight: normal;
    color: white;
    background-color: var(--primary-color);
    &.loading {
      top: 0px !important;
      cursor: not-allowed;
    }
  }
  .modal---close---28rLZ {
    display: none !important;
  }
}

.save-version-modal.titled-modal {
  max-width: 40em;
  height: inherit;
  border: 0 !important;

  .modal---modal-footer---20LPi {
    background-color: inherit !important;
  }
  .save-version-modal-button {
    border-radius: 0;
    font-weight: normal;
    color: white;
    background-color: var(--primary-color);
    &.loading {
      top: 0px !important;
      cursor: not-allowed;
    }
    &:disabled{
      cursor: not-allowed;
      background-color: dimgray;
      pointer-events: all !important;
    }
  }
  .modal---close---28rLZ {
    display: none !important;
  }
}
.import-version-modal.titled-modal {
  max-width: 40em;
  height: inherit;
  border: 0 !important;

  .modal---modal-footer---20LPi {
    background-color: inherit !important;
    display: flex;
    float: right;
  }
  .submit-button-modal{
    margin-left: 8px;
    &:focus{
      outline: none;
    }
  }
  .import-version-modal-button {
    border-radius: 0;
    font-weight: normal;
    color: white;
    background-color: var(--primary-color);
    &.loading {
      top: 0px !important;
      cursor: not-allowed;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: dimgray;
      pointer-events: all !important;
    }
  }
  .modal---close---28rLZ {
    display: none !important;
  }
}

.scope {
  &bar {
    background-color: var(--gray);
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  &-options {
    &-button {
      background: transparent;
      border: 0 none;
      letter-spacing: 0.02rem;
      line-height: 1;
      padding: 0.625rem 0.5rem;
      transition: background-color 0.15s ease-in;
      &:focus, &:hover {
        background-color: var(--black-15);
        cursor: pointer;
        color: var(--primary-color-darker);
      }
      i {
        padding-right: 0.375rem;
      }
      &:disabled {
        cursor: not-allowed;
        &:hover, &:focus {
          background-color: unset;
          color: graytext;
        }
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    &-item {
      &.open {
        & button {
          background: #bebebe;
        }
      }
      &.submit-button {
        display: flex;
        align-items: center;
        button {
          background-color: var(--primary-color);
          color: white;
          padding: 0.425rem 1rem;
          border-radius: 1rem;
          height: 80%;
          &:hover {
            background-color: var(--primary-color-darker);
            color: #e6e6e6
          }
          &:disabled {
            background-color: #c8c8c8;

          }
        }
      }
    }
  }
  &-text {
    &-item {
      &:not(:last-of-type) {
        &:after {
          content: '|';
          padding: 0 0.25rem;
        }
      }
    }
    &-list {
      align-self: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
